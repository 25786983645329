import React from "react"
import { Link } from "gatsby"
import "../styles/404.scss"

const NotFoundPage = () => {
	return (
		<div className="contentContainer">
			<div className="uk-section uk-section-primary">
				<div className="uk-container uk-container-small">
					<div className="contentWrapper">
						<h1>Page not found</h1>
						<p>Sorry 😔, we couldn’t find what you were looking for.</p>
						<Link to="/">Go home</Link>.
					</div>
				</div>
			</div>
		</div>
	)
}

export default NotFoundPage

export const Head = () => <title>Not found</title>
